import React from 'react';

import { Layout } from '../components/common';
import { MetaData } from '../components/common/meta';
import { Hero } from '../components/InnerPages/Hero';
import CashAdvanceIcon from '../images/CashAdvance';

import style from '../styles/pages/extra-money.scss';

import { Helmet } from 'react-helmet';

interface ICashAdvanceMoneyProps {
  location: any;
}

const CashAdvanceMoney = ({ location }: ICashAdvanceMoneyProps) => {
  return (
    <>
      <Helmet>
        <script type="text/javascript">{`
       var _clcktt = _clcktt || {};
        _clcktt['cIdDesktop'] = "39358";
        _clcktt['cIdMobile'] = "223833";
        (function() {
            var ca = document.createElement('script'); ca.type = 'text/javascript';
            ca.async = true;
            ca.src = '//revpie.com/api/js/extractor.js';
            var sc = document.getElementsByTagName('script')[0]; sc.parentNode.insertBefore(ca, sc);
        })();
      `}</script>
        <style>{`
        .container {
          background-color: initial !important;
        }

        main > div {
          background-color: #fff;
        }
      `}</style>
      </Helmet>
      <MetaData data={{}} location={location} title="Need money before your paycheck?" />
      <Layout headerClassName={style.header} mainClass={style.main}>
        <Hero
          className={style.hero}
          icon={<CashAdvanceIcon />}
          title="Need money before your paycheck?"
          description="Get a cash advance of up to $250 before your paycheck"
        />
        <div className={style.content}>
          <div className="container">
            <div id="clcktt"></div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default CashAdvanceMoney;
